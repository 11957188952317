import React, { useState } from 'react';
import { useStyles } from './GoalsDuringEval.styles';
import classnames from 'classnames';
import { Link, Typography } from '@mui/material';
import EmptyGoalsViewIcon from '@assets/img/emptyGoalsIcon.png';
import { useAchievedGoalsForEval } from '../../hooks/useAchievedGoalsForEval';
import { GoalsTableRow } from './components/GoalsTableRow/GoalsTableRow';
import { UserGoalPageData } from '@modules/EvaluationModule/interfaces/UserGoalPageData';
import { UserGoalPreviewDialog } from '../UserGoalPreview/UserGoalPreviewDialog';
import { GoalsStatus } from '@modules/EvaluationModule/enums/GoalsStatus';
import { useUserGoalsData } from '../../hooks/useUserGoals';
import { useSelector } from 'react-redux';
import { evaluateeNameSelector } from '@modules/EvaluationModule/pages/UserEvalPage/redux/selectors';
import { DataState } from '@shared/enums/DataState';
import { RocketLoader } from '@shared/components/RocketLoader';
import { NewGoalModal } from '../NewGoalModal/NewGoalModal';
import { useParams } from 'react-router-dom';
import { evalPageDataSelector } from '@modules/EvaluationModule/pages/EvalPage/redux/selectors';
import { EvaluationStatus } from '@modules/EvaluationModule/enums/EvaluationStatus';
import { useModalState } from '@shared/hooks/useModalState';
import { userGoalsInProgressDataSelector } from '../../redux/selectors';
import { userPermissionsSelector } from '@modules/App/redux/permissions/selectors';

interface Props {
  goalCreationAvailable?: boolean;
}

export const GoalsDuringEval: React.FC<Props> = ({ goalCreationAvailable }) => {
  const styles = useStyles();
  const { id } = useParams();
  const achievedGoalsDataForEval = useAchievedGoalsForEval();
  useUserGoalsData(GoalsStatus.InProgress);
  const { data, state } = useSelector(userGoalsInProgressDataSelector);
  const isLoading = state === DataState.Pending && achievedGoalsDataForEval.state === DataState.Pending;
  const isFailed = state === DataState.Rejected;
  const [chosenGoal, setChosenGoal] = useState<UserGoalPageData | null>(null);
  const { openModal: openNewModal, closeModal: closeNewModal, isModalOpen } = useModalState();
  const isGoalsEmpty = data?.length === 0 && achievedGoalsDataForEval.data?.length === 0;
  const evaluateeName = useSelector(evaluateeNameSelector);
  const evaluateeFirstName = evaluateeName?.split(' ')[0];
  const evalPageData = useSelector(evalPageDataSelector);
  const isEvalClose = evalPageData.data?.status === EvaluationStatus.Closed;
  const userActionsPermissions = useSelector(userPermissionsSelector);
  const smartGoalsPermissions = userActionsPermissions.data?.smartGoals;
  const isGoalCreationAvailable = smartGoalsPermissions?.goals.isCreatable && goalCreationAvailable && !isEvalClose;

  const openModal = (goal: UserGoalPageData) => {
    setChosenGoal(goal);
  };

  const closeModal = () => {
    setChosenGoal(null);
  };

  return isFailed ? null : (
    <div className={styles.root}>
      <div className={styles.title}>S.M.A.R.T goals</div>
      {isLoading ? (
        <div className={styles.loaderHolder}>
          <RocketLoader />
        </div>
      ) : isGoalsEmpty ? (
        <div className={styles.emptyView}>
          <div>
            <img src={EmptyGoalsViewIcon} className={styles.emptyIcon} />
          </div>
          <div>
            <div className={styles.emptyViewTitle}>{evaluateeFirstName} doesn`t have any goals yet</div>
            {isGoalCreationAvailable && (
              <NewGoalModal openModal={openNewModal} closeModal={closeNewModal} isOpen={isModalOpen} />
            )}
          </div>
        </div>
      ) : (
        <div className={styles.goalsTableSmall}>
          <table className={styles.tableRoot}>
            <thead>
              <tr className={classnames(styles.tableHeader, styles.goalsTableHeader, styles.goalsTableHeaderSmall)}>
                <td>Goal</td>
                <td colSpan={2}>Due</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={3} className={classnames(styles.goalItemSubheader, styles.goalStatusAchieved)}>
                  <Typography variant="body1" className={styles.goalStatusAchieved}>
                    Achieved since last evaluation
                  </Typography>
                </td>
              </tr>
              {achievedGoalsDataForEval.data?.length === 0 ? (
                <tr>
                  <td className={styles.noGoals}>No goals set or achieved since the last evaluation</td>
                </tr>
              ) : (
                achievedGoalsDataForEval.data?.map((goal, i) => (
                  <GoalsTableRow data={goal} key={i} onClick={() => openModal(goal)} />
                ))
              )}

              <tr>
                <td colSpan={3} className={classnames(styles.goalItemSubheader, styles.goalStatusProgress)}>
                  <Typography variant="body1">In progress</Typography>
                </td>
              </tr>
              {data?.length === 0 ? (
                <td className={styles.noGoals}>No recent goals in progress</td>
              ) : (
                data?.map((goal, i) => <GoalsTableRow data={goal} key={i} onClick={() => openModal(goal)} />)
              )}
              <tr>
                <td colSpan={2}>
                  <Link className={styles.goalsLoadMore} onClick={() => window.open(`/users/${id}/goals`, '_blank')}>
                    View all
                  </Link>
                </td>
                <td>
                  {isGoalCreationAvailable && (
                    <NewGoalModal openModal={openNewModal} closeModal={closeNewModal} isOpen={isModalOpen} />
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      <UserGoalPreviewDialog
        goal={chosenGoal as UserGoalPageData}
        close={closeModal}
        isModalOpen={Boolean(chosenGoal)}
      />
    </div>
  );
};
