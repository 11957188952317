import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {},
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: COLORS.TEXT.PRIMARY,
    fontSize: 24,
    fontWeight: '500',
    marginTop: 48,
    marginBottom: 24,

    [BREAKPOINTS.MOBILE]: {
      fontSize: 18,
      marginBottom: 16,
    },
  },
  columnFeedbackContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 5,
  },
});
