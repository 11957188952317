import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    background: COLORS.HIGHLIGHTS.NEUTRAL,
    borderRadius: 8,
    color: COLORS.TEXT.PRIMARY,
    height: 'fit-content',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    columnGap: 20,
    padding: '12px 9px 12px 16px',
  },
  header: {
    textTransform: 'capitalize',
    fontSize: '14px',
    fontWeight: '500',
  },
  notCommented: {
    color: COLORS.TEXT.SECONDARY,
  },
  needDev: {
    background: COLORS.HIGHLIGHTS.CRITICAL,
    color: COLORS.STATUS.CRITICAL,
  },
  markNotes: {
    whiteSpace: 'pre-wrap',
  },
  tooltip: {
    alignSelf: 'center',
  },
});
